import usacSettings from "./usacSettings";
import { useEffect, useState, useRef } from "react";
import { authProvider } from "../authProvider";
import {
    DetailsList,
    IconButton,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField,
} from "@fluentui/react";
import "./styles/CompanySearch.css";
const CompanySearch = ({ usacItem, onCompanySelected, onClose }) => {
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState(
        usacItem ? usacItem.organizationName : ""
    );
    const [token, setToken] = useState("");
    const textFieldRef = useRef(null);
    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
    }, []);

    useEffect(() => {
        if (!token || !searchTerm) {
            return;
        }
        fetch(
            `${usacSettings.rootUrl}/findCompanies?searchText=${searchTerm}`,
            {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            }
        )
            .then(async (res) => {
                if (res.ok) {
                    const data = await res.json();
                    setResults(data);
                    return data;
                } else {
                    const errText = await res.text();
                    console.error("Error retrieving results: " + errText);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, [searchTerm, token]);

    const columns = [
        {
            key: "companyName",
            name: "CW Company Name",
            fieldName: "companyName",
        },
        {
            key: "selectColumn",
            name: "Action",
            onRender: (item) => {
                return (
                    <PrimaryButton
                        text="Assign BEN to Cw Company"
                        onClick={() => {
                            raiseCompanySelected(item);
                        }}
                    />
                );
            },
            minWidth: 300,
        },
    ];

    const raiseCompanySelected = (company) => {
        if (onCompanySelected) {
            onCompanySelected(company);
        }
    };

    const raiseOnClose = () => {
        console.log("Close called.  onClosed set? " + onClose ? "Yes" : "No");
        if (onClose) {
            console.log("closing Company Search dialog.");
            onClose();
        }
    };
    return (
        <div className="searchContainer">
            <div className="searchBox">
                <Stack horizontal horizontalAlign="space-between" styles={{root: { width: "100%" }}}>
                    <div>Organization: {usacItem?.organizationName}</div>
                    <Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                raiseOnClose();
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal>
                    <TextField
                        componentRef={textFieldRef}
                        value={searchTerm}
                        label="Search"
                        onChange={(e, v) => {
                            setSearchTerm(v);
                        }}
                        onClose={() => {
                            setSearchTerm(null);
                        }}
                        onFocus={()=>{
                            if (textFieldRef.current){
                                textFieldRef.current.select();
                            }
                        }}
                    />
                </Stack>
                <DetailsList
                    columns={columns}
                    items={results}
                    selectionMode={SelectionMode.none}
                />
            </div>
        </div>
    );
};

export default CompanySearch;
