import React, { useState, useRef, useEffect } from 'react';
import { TextField } from "@fluentui/react";
import "./EditableField.css";
const EditableField = ({ value, onChange }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const textFieldRef = useRef(null);

    const handleTextClick = () => {
        if (isEditing) return;
        setIsEditing(true);
    };

    const handleBlur = () => {
        setIsEditing(false);
        if (onChange) {
            onChange(currentValue);
        }
    };

    const handleChange = (e, newValue) => {
        setCurrentValue(newValue);
    };

    useEffect(() => {
        if (isEditing && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [isEditing]);

    return (
        <div className="editableField" onClick={handleTextClick}>
            {isEditing ? (
                <TextField
                    componentRef={textFieldRef}
                    value={currentValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            ) : (
                <span>{currentValue}</span>
            )}
        </div>
    );
};

export default EditableField;