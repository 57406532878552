import { useEffect, useState } from "react";
import { authProvider } from "../authProvider";
import {
    DetailsList,
    Dropdown,
    IconButton,
    Stack,
    SelectionMode,
    Tooltip,
    TooltipHost,
} from "@fluentui/react";
import usacSettings from "./usacSettings";
import CompanySearch from "./CompanySearch";
import OpportunitySearch from "./OpportunitySearch";
import LoadingDialog from "./LoadingDialog";

const rootUrl = usacSettings.rootUrl;
const getUsacYears = () => {
    // we want years from 5 years prior to the current year to the next year as strings
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 5; i <= currentYear + 1; i++) {
        years.push({ key: i.toString(), text: i.toString() });
    }
    return years;
};
const UsacMain = () => {
    const [token, setToken] = useState("");
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [usacYear, setUsacYear] = useState(
        new Date().getFullYear().toString()
    );
    const [companySearchItem, setCompanySearchItem] = useState("");
    const [opportunitySearchItem, setOpportunitySearchItem] = useState(null);
    const [usacItems, setUsacItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // Authenticate
    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
        authProvider.getIdToken().then((res) => {
            setIsAuthorized(() => {
                return res.idToken.claims.roles.includes("USAC.User");
            });
        });
    }, []);
    useEffect(() => {
        if (token && usacYear) {
            setIsLoading(true);
            fetch(`${rootUrl}/getYear?year=${usacYear}`, {
                method: "GET",
                headers: { Authorization: `Bearer ${token}` },
            })
                .then(async (res) => {
                    if (res.ok) {
                        const data = await res.json();
                        setUsacItems(() => {
                            return data;
                        });
                        setIsLoading(false);
                        console.log(data);
                    } else {
                        throw new Error("Failed to get USAC data");
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [token, usacYear]);

    const updateCompanyBen = (company) => {
        const companyId = company.companyRecId;
        const benNumber = companySearchItem.benNumber;
        const item = companySearchItem;

        // post to ${rootUrl}/setUsacCompany with ben and cwCompanyReqId
        fetch(`${rootUrl}/setUsacCompany`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ben: benNumber,
                benName: companySearchItem.organizationName,
                cwCompanyRecId: companyId,
            }),
        }).then((res) => {
            if (res.ok) {
                console.log("Company updated successfully");
            } else {
                console.error("Failed to update company");
            }
        });

        setUsacItems((prevItems) => {
            //const newItem = { ...item, cwCompanyId: companyId, cwCompanyName: company.companyName };
            // find the index of the item in prevItems
            const newItems = prevItems.map((item) => {
                if (item.benNumber != companySearchItem.benNumber) {
                    return item;
                }
                return {
                    ...item,
                    cwCompanyId: companyId,
                    cwCompanyName: company.companyName,
                };
            });
            return newItems;
        });

        // todo: call the api to update the company in CW
        setCompanySearchItem(() => null);
    };

    const updateOpportunityFrn = (opportunity) =>{
        const opportunityId = opportunity.id;
        const item = opportunitySearchItem;

        // post to ${rootUrl}/setUsacOpportunity with opportunityId and frnNumber
        fetch(`${rootUrl}/assignToOpportunity`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                opportunityId: opportunityId,
                frn: opportunitySearchItem.frnNumber,
            }),
        }).then((res) => {
            if (res.ok) {
                console.log("Opportunity updated successfully");
            } else {
                console.error("Failed to update opportunity");
            }
        });

        setUsacItems((prevItems) => {
            const newItems = prevItems.map((item) => {
                if (item.frnNumber != opportunitySearchItem.frnNumber) {
                    return item;
                }
                return {
                    ...item,
                    opportunityId: opportunityId,
                    opportunityName: opportunity.opportunityName,
                };
            });
            return newItems;
        });
        // hide the search.
        setOpportunitySearchItem(() => null);
    }

    // Example data
    //{
    //     "frnNumber": "2499005507",
    //     "benNumber": "125410",
    //     "opportunityId": null,
    //     "opportunityName": "",
    //     "organizationName": "Brookville Area School Dist",
    //     "cwCompanyId": null,
    //     "cwCompanyName": "",
    //     "status": null,
    //     "state": "PA",
    //     "frnStatus": "Pending",
    //     "pendingReason": "FCDL Issued"
    // }
    const columns = [
        {
            key: "frnNumber",
            name: "FRN#",
            fieldName: "frnNumber",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "narrative",
            name: "Narrative",
            fieldName: "narrative",
            minWidth: 100,
            maxWidth: 200,
            onRender: (item) => {
                return (
                    <span
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                        {item.narrative}
                    </span>
                );
            },
        },
        {
            key: "benNumber",
            name: "BEN#",
            fieldName: "benNumber",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "organizationName",
            name: "Organization",
            fieldName: "organizationName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "cwCompanyName",
            name: "CW Company",
            fieldName: "cwCompanyName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return (
                    <span
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                        {item.cwCompanyName}{" "}
                        <TooltipHost content="Look up and assign BEN to company">
                        <IconButton
                            iconProps={{ iconName: "Search" }}
                            onClick={() => {
                                setCompanySearchItem(() => item);
                                console.log("Search clicked");
                            }}
                        />
                        </TooltipHost>
                    </span>
                );
            },
        },
        {
            key: "opportunityId",
            name: "Opp #",
            fieldName: "opportunityId",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "opportunityName",
            name: "Opportunity",
            fieldName: "opportunityName",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return (
                    <span
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                        {item.opportunityName}{" "}
                        <TooltipHost
                            content={
                                item.cwCompanyName === ""
                                    ? "Company BEN must be assigned prior to assigning an opportunity"
                                    : "Look up and assign opportunity"
                            }
                        >
                            <IconButton
                                iconProps={{ iconName: "Search" }}
                                enabled={item.cwCompanyName !== ""}
                                onClick={() => {
                                    setOpportunitySearchItem(() => item);
                                    console.log("Search clicked");
                                }}
                            />
                        </TooltipHost>
                    </span>
                );
            },
        },
        {
            key: "state",
            name: "State",
            fieldName: "state",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "frnStatus",
            name: "FRN Status",
            fieldName: "frnStatus",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "pendingReason",
            name: "Pending Reason",
            fieldName: "pendingReason",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
    ];
    return (
        <div>
            {isAuthorized && (
                <div>
                    <h2>USAC FRN Status Information and Import</h2>
                    <Stack>
                        <Stack horizontal>
                            <Dropdown
                                label="Year"
                                options={getUsacYears()}
                                onChange={(e, o) => setUsacYear(o.key)}
                                selectedKey={usacYear}
                            />
                        </Stack>
                        <DetailsList
                            columns={columns}
                            items={usacItems}
                            selectionMode={SelectionMode.none}
                        />
                    </Stack>
                </div>
            )}
            {!isAuthorized && (
                <div>
                    <h2>Unauthorized</h2>
                    <p>
                        You are not authorized to access this tool. If you
                        believe you should have access, please contact your team
                        leader and have them submit a request to have access
                        granted.
                    </p>
                </div>
            )}
            {companySearchItem && (
                <CompanySearch
                    usacItem={companySearchItem}
                    onCompanySelected={updateCompanyBen}
                    onClose={() => {
                        setCompanySearchItem(null);
                    }}
                />
            )}
            {opportunitySearchItem && (
                <OpportunitySearch
                    usacItem={opportunitySearchItem}
                    onOpportunitySelected={updateOpportunityFrn}
                    onClose={() => {
                        setOpportunitySearchItem(null);
                    }}
                />
            )}
            {isLoading && <LoadingDialog />}
        </div>
    );
};
export default UsacMain;
