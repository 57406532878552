import { useEffect, useRef, useState } from "react";
import { authProvider } from "../authProvider";
import usacSettings from "./usacSettings";
import {
    DetailsList,
    IconButton,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField,
} from "@fluentui/react";

const closedStatuses = [
    "Won",
    "Lost",
    "Cancelled",
    "Lost - Customer Budget",
    "Lost - Solution Not a Fit",
    "Rejected from Portal",
    "Converted to Other Opportunity"
];
const OpportunitySearch = ({ usacItem, onOpportunitySelected, onClose }) => {
    const [results, setResults] = useState([]);
    const [searchTerm, setSearchTerm] = useState(
        usacItem?.opportunityId ? usacItem.opportunityId : ""
    );
    const [token, setToken] = useState("");
    const textFieldRef = useRef(null);

    const columns = [
        {
            key: "id",
            name: "Opp #",
            fieldName: "id",
            maxWidth: 150,
        },
        {
            key: "name",
            name: "Summary",
            fieldName: "name",
            minWidth: 400,
            onRender: (item) => {
                return (
                    <span
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                    >
                        {item.name}
                    </span>
                );
            },
        },
        {
            key: "status",
            name: "Status",
            onRender: (item) => {
                return <span>{item.status.name}</span>;
            },
            minWidth: 150,
        },
        {
            key: "selectColumn",
            name: "Action",
            onRender: (item) => {
                if (closedStatuses.includes(item.status.name)) {
                    return <div style={{color: "red"}}>Cannot assign to closed Opp</div>;
                }
                return (
                    <PrimaryButton
                        text="Assign FRN to Opportunity"
                        onClick={() => {
                            raiseOpportunitySelected(item);
                        }}
                    />
                );
            },
            minWidth: 300,
        },
    ];
    const raiseOpportunitySelected = (opportunity) => {
        if (onOpportunitySelected) {
            onOpportunitySelected(opportunity);
        }
    };
    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
    }, []);

    useEffect(() => {
        var handler = null;
        if (!token || !usacItem || usacItem.benNumber === undefined) {
            return;
        }
        handler = setTimeout(() => {
            fetch(
                `${usacSettings.rootUrl}/getOpportunities?ben=${usacItem.benNumber}&searchText=${searchTerm}`,
                {
                    method: "GET",
                    headers: { Authorization: `Bearer ${token}` },
                }
            )
                .then(async (res) => {
                    if (res.ok) {
                        const data = await res.json();
                        setResults(data);
                        return data;
                    } else {
                        const errText = await res.text();
                        console.error("Error retrieving results: " + errText);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }, 500);
        return () => {
            if (handler) {
                //clearTimeout(handler);
            }
        };
    }, [searchTerm, usacItem, token]);
    const raiseOnClose = () => {
        console.log("Close called.  onClosed set? " + onClose ? "Yes" : "No");
        if (onClose) {
            console.log("closing Company Search dialog.");
            onClose();
        }
    };
    return (
        <div className="searchContainer">
            <div className="searchBox">
                <Stack horizontal horizontalAlign="space-between" styles={{root: {width: '100%'}}}>
                    <div>
                        FRN#: {usacItem?.frnNumber}
                        <br />
                        {usacItem?.narrative}
                    </div>
                    <Stack.Item>
                        <IconButton
                            iconProps={{ iconName: "Cancel" }}
                            onClick={() => {
                                raiseOnClose();
                            }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack horizontal>
                    <TextField
                        componentRef={textFieldRef}
                        value={searchTerm}
                        label="Search"
                        onChange={(e, v) => {
                            setSearchTerm(v);
                        }}
                        onClose={() => {
                            setSearchTerm(null);
                        }}
                        onFocus={() => {
                            if (textFieldRef.current) {
                                textFieldRef.current.select();
                            }
                        }}
                    />
                </Stack>
                <DetailsList
                    columns={columns}
                    items={results}
                    selectionMode={SelectionMode.none}
                />
            </div>
        </div>
    );
};

export default OpportunitySearch;
