import * as React from "react";
import * as ReactDOM from "react-dom"
import { Fabric } from '@fluentui/react/lib/Fabric'
import { Modal } from '@fluentui/react/lib/Modal';
//import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';
import { DatePicker, DayOfWeek, IDatePickerStrings, IDatePickerStyles } from '@fluentui/react-date-time';
import { TextField, ITextFieldStyles } from '@fluentui/react/lib/TextField';
import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { CommandBar, ICommandBarItemProps  } from '@fluentui/react/lib/CommandBar';
import { Stack, IStackProps, IStackStyles, IStackTokens } from '@fluentui/react/lib/Stack';
import { Client, AbsenceRequest } from "./ApiClient";
import { Label, ILabelStyles } from '@fluentui/react/lib/Label';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
const DayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',

    isRequiredErrorMessage: 'Start date is required.',

    invalidInputErrorMessage: 'Invalid date format.'
};
const commendBarItems: ICommandBarItemProps[] = [
    {
        key: 'submitRequest',
        text: 'Submit Request'
     
    }
];
interface RequestState {
    value: Date,
    firstDayOfWeek: DayOfWeek,
    employeeEmail: string,
    employeeName: string,
    managerName: string,
    managerEmail: string,
    typeOfAbsence: string,
    dateFrom: Date,
    dateTo: Date,
    numberOfHours: string,
    reasonForAbsence: string,
    employeeSignature: string,
    isSaving: boolean,
    showComplete: boolean,
    showNotSubmitted: boolean
}
interface IRequestFormProps {
    onComplete?(): void;
}
const stackTokens = { childrenGap: 3 };
const stackStyles: Partial<IStackStyles> = { root: { width: 450 } };
const labelStyles: Partial<ILabelStyles> = { root: { width: 800 } };
const datePickerStyles: Partial<IDatePickerStyles> = { root: { width: 200 } };
const dropDownStyles: Partial<IDropdownStyles> = { root: { width: 250 } };
const textFieldStyles: Partial<ITextFieldStyles> = { root: { width: 250 } };
const numberOfHoursTextFieldStyles: Partial<ITextFieldStyles> = { root: { width: 75 } };
const stackButtonTokens: IStackTokens = { childrenGap: 20 };
const stackButtonStyles: Partial<IStackStyles> = { root: { padding: 15 } };
const absenseRequestReasons= [
    { key: "Military Service", text: "Military Service" }, 
    { key: "Reserve Duty", text: "Reserve Duty" },
    { key: "Jury Duty", text: "Jury Duty" },
    { key: "Bereavement Leave", text: "Bereavement Leave" }, 
   /* { key: "Family Medical Leave Act", text: "Family Medical Leave Act" }, */
    { key: "PTO - Paid Time Off", text: "PTO - Paid Time Off" }];
export class RequestForm extends React.Component<IRequestFormProps, RequestState> {
    public static defaultProps: Partial<IRequestFormProps> = {
        onComplete: null
    };
    constructor(props) {
        super(props);

        this.state = {
            firstDayOfWeek: DayOfWeek.Sunday,
            value: new Date(),
            employeeEmail: "",
            employeeName: "",
            managerName: "",
            managerEmail: "",
            typeOfAbsence: "",
            dateFrom: null,
            dateTo: null,
            numberOfHours: "",
            reasonForAbsence: "",
            employeeSignature: "",
            isSaving: false,
            showComplete: false,
            showNotSubmitted: false
        };
        var client = new Client();
        client.getInitialData().then((data) => {
            this.setState({
                employeeEmail: data.employeeEmail,
                employeeName: data.employeeName,
                managerName: data.managerName,
                managerEmail: data.managerEmail
            });
        });
    }

    public render(): JSX.Element {
        const firstDayOfWeek = this.state.firstDayOfWeek;
        const value = this.state.value;
        const desc = 'This field is required. One of the support input formats is year dash month dash day.';
        return (
            <div>
                <Modal isOpen={this.state.isSaving} isBlocking={true}>
                    <div style={{ padding: "10px" }}>
                        <Spinner label="Submitting Request..." />
                    </div>
                </Modal>
                <Dialog hidden={!this.state.showComplete}
                    onDismiss={this._closeCompleteDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Absence Request Submitted!",
                        subText: "Your absence request has been submitted.  You will receive a notification upon its review by your manager."
                    }}>
                    <DialogFooter>
                        <DefaultButton onClick={this._closeCompleteDialog} text="Ok" />
                    </DialogFooter>
                </Dialog>
                <Dialog hidden={!this.state.showNotSubmitted}
                    onDismiss={this._closeNotSubmittedDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Absence Request Not Submitted!",
                        subText: "Your absence request was not submitted. A required field may be missing or invalid.  Please check the form and submit again."
                    }}>
                    <DialogFooter>
                        <DefaultButton onClick={this._closeNotSubmittedDialog} text="Ok" />
                    </DialogFooter>
                </Dialog>
                <div></div>
                <div>
                    <Stack horizontal={false} tokens={stackTokens} styles={stackStyles} >
                        <h1>New Absence Request</h1>
                        <Label styles={labelStyles}>To request an absence from your work duties, please fill out this form. Your name, email address, Manager's Name and
                            Manager's email address will fill automatically. Fill in the remaining fields, type your full name in the Employee
                            Signature field and click the 'Submit to Manager' button. Your manager will review the request and you will be informed
                            via email of their decision.</Label>

                        <DatePicker
                            label="Date of Request"
                            isRequired={true}
                            allowTextInput={true}
                            ariaLabel={desc}
                            firstDayOfWeek={firstDayOfWeek}
                            strings={DayPickerStrings}
                            value={value!}
                            onSelectDate={this._onSelectDate}
                            styles={datePickerStyles}
                            disabled={true}
                        />


                        <TextField label="Employee Email" required={true} value={this.state.employeeEmail} disabled={true} onChange={this._updateEmployeeEmail} styles={textFieldStyles} />
                        <TextField label="Employee Name" required={true} value={this.state.employeeName} disabled={true} onChange={this._updateEmployeeName} styles={textFieldStyles} />
                        <TextField label="Team Leader Name" required={true} value={this.state.managerName} disabled={true} onChange={this._updateManagerName} styles={textFieldStyles} />
                        <TextField label="Team Leader Email" required={true} value={this.state.managerEmail} disabled={true} onChange={this._updateManagerEmail} styles={textFieldStyles} />
                        <Dropdown label="Type of Absence" required={true} onChanged={this._onSelectTypeOfAbsence} styles={dropDownStyles} options={absenseRequestReasons}></Dropdown>
                        <DatePicker label="Absent From" isRequired={true}
                            allowTextInput={true} firstDayOfWeek={firstDayOfWeek} value={this.state.dateFrom} onSelectDate={this._onSelectFrom} styles={datePickerStyles} />
                        <DatePicker allowTextInput={true} firstDayOfWeek={firstDayOfWeek} isRequired={true} label="To" value={this.state.dateTo} minDate={this.state.dateFrom}  onSelectDate={this._onSelectTo} styles={datePickerStyles} />
                        <TextField label="Number of Hours" required={true} value={this.state.numberOfHours} validateOnFocusOut onGetErrorMessage={this._getNumberOfHoursError} onChange={this._updateNumberOfHours} styles={numberOfHoursTextFieldStyles} />
                        <TextField label="Reason for Absence" required={true} multiline value={this.state.reasonForAbsence} onChange={this._updateReasonForAbsence} rows={4} />
                    </Stack></div>

                <Stack horizontal tokens={stackButtonTokens} styles={stackButtonStyles}>
                    <PrimaryButton text="Submit Request" onClick={this._submitRequest} />
                    <DefaultButton text="Cancel Request" onClick={this._cancelRequest} />
             
                </Stack>

            </div>
        );
    }

   
    private _onSelectDate = (date: Date | null | undefined): void => {
        this.setState({ value: date });
    };
    private _onSelectFrom = (date: Date | null | undefined): void => {
        this.setState({ dateFrom: date });
    };
    private _onSelectTo = (date: Date | null | undefined): void => {
        var fromDate = this.state.dateFrom;

        if (date < fromDate) {

        } else {
            this.setState({ dateTo: date });
        }

       
    };
    private _onSelectTypeOfAbsence = (value: IDropdownOption): void => {
        this.setState({ typeOfAbsence: value.key.toString() });
    };
    private _onClick = (): void => {
        this.setState({ value: null });
    };

    private _submitRequest = (): void => {
        this.setState({ isSaving: true });
        var client = new Client();
        var request = new AbsenceRequest();
        request.typeOfAbsence = this.state.typeOfAbsence;
        request.employeeEmail = this.state.employeeEmail;
        request.employeeSignature = this.state.employeeSignature;
        request.employeeName = this.state.employeeName;
        request.managerName = this.state.managerName;
        request.managerEmail = this.state.managerEmail;
        request.dateOfRequest = this.state.value;
        request.numberOfHours = this.state.numberOfHours;
        request.absentTo = this.state.dateTo;
        request.absentFrom = this.state.dateFrom;
        request.reasonForAbsence = this.state.reasonForAbsence;
        client.absenceRequestPOST(request).then((success) => {
            if (success) {
                this._cancelRequest();
                this.setState({ isSaving: false, showComplete: true, showNotSubmitted: false });
            } else {
                this.setState({ isSaving: false, showComplete: false, showNotSubmitted: true });
            }
        });
    }
    private _updateEmployeeEmail = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ employeeEmail: newValue });
    };
    private _updateEmployeeName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ employeeName: newValue });
    };
    private _updateManagerName = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ managerName: newValue });
    };
    private _updateManagerEmail = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ managerEmail: newValue });
    };
    private _updateNumberOfHours = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        if (!newValue || newValue.length <= 5) {
            this.setState({ numberOfHours: newValue });
        }
       
    };
    private _updateReasonForAbsence = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ reasonForAbsence: newValue });
    };
    private _updateEmployeeSignature = (ev: React.FormEvent<HTMLInputElement>, newValue?: string): void => {
        this.setState({ employeeSignature: newValue });
    };
    private _cancelRequest = (): void => {
        this.setState({
            typeOfAbsence: "",
            value: new Date(),
            numberOfHours: "",
            reasonForAbsence: "",
            dateFrom: null,
            dateTo: null,
            employeeSignature: "",
        });
    }
    private _closeCompleteDialog = (): void => {
        this.setState({ showComplete: false });
        if (this.props && this.props.onComplete) {
            this.props.onComplete();
        }
    }
    private _closeNotSubmittedDialog = (): void => {
        this.setState({ showNotSubmitted: false });

    }
    private _getNumberOfHoursError(value: string): string {
        return isNaN(parseInt(value)) ? "Number of hours must be a number." : "";
    }
}