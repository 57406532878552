import { Spinner, SpinnerSize } from "@fluentui/react";
import "./styles/LoadingDialog.css";
const LoadingDialog = () => {
    return (
        <div className="loadingContainer">
            <div className="loadingBox">
                <Spinner
                    label="Loading data from USAC. Please wait..."
                    labelPosition="left"
                    size={SpinnerSize.large}
                    styles={{label: {fontSize: '20px'}}}   
                />
            </div>
        </div>
    );
};

export default LoadingDialog;