import { useEffect, useState } from "react";
import "./ChatApp.css";
import { IconButton, PrimaryButton, TextField } from "@fluentui/react";
import { authProvider } from "../authProvider";

const apiRoot = window.location.origin;
const ChatApp = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    const contextMessages = 15;

    // Authenticate
    useEffect(() => {
        authProvider.getAccessToken().then((token) => {
            setToken(token.accessToken);
        });
    }, []);
    // chat messages have two fields: role and content. We want to display "{role}: {content}"
    const onSend = async () => {
        if (input.trim() === "") return;
        var newMessages = [...messages, { content: input, role: "user" }];
        setMessages(() => newMessages);
        const messagesToSend = newMessages.slice(-contextMessages);
        setLoading(true);
        var response = await fetch(apiRoot + "/api/hal", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify( messagesToSend ),
        });
        if (response.ok) {
            response = await response.json();
            setMessages((newMsgs)=>[...newMsgs, response]);
        }
       
        setInput("");
    };
    return (
        <div className="mainContainer">
            <div className="headingContainer">
                <img
                    src="/loki.png"
                    alt="A 3d cartoon rendering of the mischievous god Loki"
                />
                <div className="heading">Nettech Loki AI</div>
            </div>
            <div className="chatContainer">
                <div className="chatMessages">
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={
                                msg.role === "user"
                                    ? "userMessage"
                                    : "botMessage"
                            }
                        >
                            <span style={{color: msg.role === "user"? "red" : "green"}}>{msg.role === "user" ? "You: " : "Loki: "}</span>
                            {msg.content}
                        </div>
                    ))}
                </div>
                <div className="chatPrompt">
                    <TextField
                        styles={{root: {width: "600px"}}}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={(e) => e.key === "Enter" && onSend()}
                        multiline={true}
                    />
                    <IconButton iconProps={{iconName: "send"}} onClick={onSend} text="Send" />
                </div>
            </div>
           
        </div>
    );
};

export default ChatApp;
