import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import EditableField from './EditableField';
import { DefaultButton, Label, PrimaryButton } from '@fluentui/react';

const initialBacklogItem = {
    id: uuidv4(),
    title: "",
    description: "",
    notes: [],
    status: "",
    requestedBy: "", 
    lastModified: new Date(),
    lastModifiedBy: "",
    createdOn: new Date(),
    createdBy: "",
    position: 0,
};

const BacklogComponent = () => {
    const [backlogItems, setBacklogItems] = useState([]);

    const handleAddItem = () => {
        const newItem = { 
            ...initialBacklogItem, 
            id: uuidv4(), 
            title: `Item ${backlogItems.length + 1}`, 
            position: backlogItems.length + 1
        };
        setBacklogItems([...backlogItems, newItem]);
    };

    const handleRemoveItem = (id) => {
        setBacklogItems(backlogItems.filter(item => item.id !== id));
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(backlogItems);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        const updatedItems = items.map((item, index) => ({
            ...item,
            position: index + 1
        }));
        setBacklogItems(updatedItems);
    };

    
    const onItemFieldChange = (item, field, value)=>{
        setBacklogItems(oldBli=> oldBli.map(i => {
            if(i.id === item.id){
                return {...i, [field]: value}
            }
            return i;
        }));
    }
    const onDescriptionChange = (item, value) =>{
        onItemFieldChange(item, 'description', value);
    };
    const onTitleChange = (item, value) =>{
        onItemFieldChange(item, 'title', value);
    }
    return (
        <div>
            <h1>Backlog</h1>
            <PrimaryButton onClick={handleAddItem}>Add Item</PrimaryButton>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="backlog">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {backlogItems.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: 'none',
                                                padding: 16,
                                                margin: '0 0 8px 0',
                                                minHeight: '50px',
                                                backgroundColor: '#fff',
                                                color: '#333',
                                                ...provided.draggableProps.style
                                            }}
                                        >
                                            <div>
                                                <Label>Title</Label>
                                                <EditableField value={item.title} onChange={(newValue)=>{onTitleChange(item, newValue)}}/>
                                                <Label>Description</Label>
                                                <EditableField value={item.description} onChange={(newValue)=>{onDescriptionChange(item, newValue)}}/>
                                                <DefaultButton onClick={() => handleRemoveItem(item.id)}>Remove</DefaultButton>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default BacklogComponent;